.notices {
    padding-top: 1rem;
    padding-right: 0.75rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding: 1em;
}

.notices.info {
    position: relative;
    color: #015692;
    background-color: #e3ecf3;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #01579b;
}

.notices.info a {
    text-decoration: underline;
}

@mixin dark-mode {
    .notices.info {
        color: inherit;
        background-color: #18232c;
        border-left-color: #015692;
    }

    .notices.info p code {
        background-color: #11191f;
    }

    .notices.info a {
        text-decoration: none;
    }
}

@media (prefers-color-scheme: dark) {
    @include dark-mode;
}

.dark {
    @include dark-mode;
}
