.blogpost-header {
    padding-bottom: 3rem;
}

.blogpost-title {
    color: #24333e;
    font-size: ms(3);
    margin-bottom: 0.5rem;
    text-align: center;

    @include desktop {
        text-align: left;
    }
}

.blogpost-date {
    display: block;
    color: #8d959b;
    font-size: $font-size-small;
    text-align: center;

    @include desktop {
        text-align: left;
    }
}

@mixin dark-mode {
    .blogpost-title {
        color: #d5dce2;
    }
}

@media (prefers-color-scheme: dark) {
    @include dark-mode;
}

.dark {
    @include dark-mode;
}


