.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
}

.container {
    @include phone {
        max-width: $grid-width-small;
    }

    @include tablet {
        max-width: $grid-width-medium;
    }

    @include desktop {
        max-width: $grid-width-large;
    }
}
