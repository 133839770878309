.ideas {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  // Divisible by 12 and 16 (LCM of 12 and 16: 48)
  max-width: 1824px;
  width: 100%;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @include tablet {
    gap: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  @include widescreen {
    grid-template-columns: repeat(3, 1fr);
  }
}

.idea {
  padding: 1rem;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #ECEFF1;
  border-radius: $border-radius;

  @include tablet {
    padding: 2rem;
  }
}

.idea-header {
  margin-bottom: 1.25rem;
}

.idea-title {
  font-size: ms(1);

  @include tablet {
    font-size: ms(2);
  }
}

@mixin dark-mode {
  .idea {
    background-color: #11191f;
    border-color: #18232c;
  }
}

@media (prefers-color-scheme: dark) {
  @include dark-mode;
}


.dark {
  @include dark-mode;
}