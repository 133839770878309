html {
  min-width: 300px;
  font-size: 100%;
  font-family: sans-serif;
  // disable auto-enlargement of small text on mobile
  text-size-adjust: 100%;
}

body {
  color: #263238;
  background-color: #FAFAFA;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: $font-weight-normal;
  // Make footer stick to the bottom if content isn't enough
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include tablet {
    font-size: $font-size-base;
  }
}

main {
  // Make footer stick to the bottom if content isn't enough
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  font: inherit;
  text-transform: none;
  -webkit-appearance: button;
  background: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: $font-weight-bold;
}

code {
  font-family: $font-family-monospace;
}

pre code {
  font-size: $font-size-small;
}

strong {
  font-weight: $font-weight-bold;
}

small {
  font-size: $font-size-small;
}

@import '~highlight.js/scss/stackoverflow-light';

// Automatic dark mode
// Keep the rules in sync with the manual dark mode below
@media (prefers-color-scheme: dark) {
  @import '~highlight.js/scss/nord';
}

// Manual dark mode
// Keep the rules in sync with the automatic dark mode above
.dark {
  @import '~highlight.js/scss/nord';

  background-color: #11191f;
  color: #bbc6ce;

  // Make images look comfortable to the eyes
  // Source: https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/#aa-dark-mode-images
  img {
    filter: brightness(0.8) contrast(1.2);
  }
}
