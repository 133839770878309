// Source: https://github.com/jgthms/bulma/blob/master/sass/utilities/mixins.sass

@mixin phone {
  @media (max-width: $breakpoint-phone) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $breakpoint-tablet) and (max-width: ($breakpoint-desktop - 1px)) {
    @content;
  }
}

@mixin touch {
  @media (max-width: ($breakpoint-desktop - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $breakpoint-desktop) and (max-width: ($breakpoint-widescreen - 1px)) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: $breakpoint-widescreen) {
    @content;
  }
}
