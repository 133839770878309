.footer-section {
    color: #8d959b;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #d4dce0;
}

.footer-text {
    font-size: $font-size-small;
    text-align: center;
}

@mixin dark-mode {
    .footer-section {
        border-top-color: #18232c;
    }
}

@media (prefers-color-scheme: dark) {
    @include dark-mode;
}

.dark {
    @include dark-mode;
}
