// Typography
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size-base: 1.125rem;
$font-size-small: 90%;

// Modular scale - for ms() function
$modular-scale-base: $font-size-base;
$modular-scale-ratio: 1.2;

// Z-index - for get-z() function
// This list represents the order in which elements will appear, from lowest to highest.
$z-index-list: (dropdown);

// Breakpoints - for breakpoints mixins
// PX for breakpoints discussion: https://github.com/twbs/bootstrap/pull/17403
$breakpoint-phone: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-widescreen: 1200px;

// Grid - for grid mixins
$grid-gutter-width: 30px;

$grid-width-small: 540px;
$grid-width-medium: 720px;
$grid-width-large: 960px;
$grid-width-extralarge: 1200px;

// Border radius
$border-radius: 4px;

// Transitions
$transition-duration: 200ms;
$transition-timing-function: ease-out;
