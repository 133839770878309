.project {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #ECEFF1;
  border-radius: $border-radius;

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet {
    margin-bottom: 3rem;
    padding: 2rem;
  }
}

.project-header {
  margin-bottom: 1rem;
}

.project-title {
  font-size: ms(1);

  @include tablet {
    font-size: ms(2);
  }
}

.project-title-link {
  color: #24333e;
  transition-property: color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover,
  &:active {
    color: #546E7A;
  }
}

.project-description {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.project-image {
  display: block;
  border-radius: $border-radius;
  transition-property: opacity;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    opacity: 0.93;
  }
}

@mixin dark-mode {
  .project {
    background-color: #11191f;
    border-color: #18232c;
  }

  .project-image {
    // Use opacity for project images
    filter: none;
    opacity: 0.7;

    &:hover {
      opacity: 0.85;
    }
  }

  .project-title-link {
    color: #d5dce2;

    &:hover,
    &:active {
      color: #f3f5f7;
    }
  }
}

@media (prefers-color-scheme: dark) {
  @include dark-mode;
}


.dark {
  @include dark-mode;
}
